// any CSS you import will output into a single css file (app.css in this case)
require('../css/minisite-bootstrap-4.scss');

//Init fos js routing
const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
global.Routing = Routing;
Routing.setRoutingData(routes);

//jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;

//Bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css';

// Bootstrap
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min.js');
global.bootstrap = bootstrap;

//HOLDER JS (Plugin pour les dimension des image)
require('holderjs/holder.min');

//GOOGLE MAPS
import { Loader } from "@googlemaps/js-api-loader"

let google_api_key = jQuery("#config_map").data("api-key");

const loader = new Loader({
    apiKey: google_api_key,
    version: "weekly",
});

loader.load().then(() => {

    jQuery(".map").each(function () {
        let id = jQuery(this).prop("id");
        let adresse = jQuery(this).data("adresse");
        let longitude = jQuery(this).data("longitude");
        let latitude = jQuery(this).data("latitude");

        initMap(id, adresse, longitude, latitude);
    })
});

function initMap(id, adresse, longitude, latitude) {
    const map = new google.maps.Map(document.getElementById(id), {
        scrollwheel: false,
        zoom: 16,
        center: { lat: latitude, lng: longitude },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true
    });

    const geocoder = new google.maps.Geocoder();
    geocodeAddress(id, adresse, geocoder, map);
}

function geocodeAddress(id, address, geocoder, resultsMap) {
    geocoder
        .geocode({ address: address })
        .then(({ results }) => {
            resultsMap.setCenter(results[0].geometry.location);

            let marker = new google.maps.Marker({
                map: resultsMap,
                position: results[0].geometry.location,
                title: address
            });

            const infowindow = new google.maps.InfoWindow({
                content: address,
            });

            marker.addListener("click", () => {
                infowindow.open({
                    anchor: marker,
                    map: resultsMap,
                    shouldFocus: false,
                });
            });
        })
        .catch((e) =>
            alert("Geocode was not successful for the following reason: " + e)
        );
}
